<template>
	<div class="d-flex justify-center flex-column flex-md-row">
		<v-btn
			class="hvr-grow actionButton mr-0 mr-md-4 mb-3 mb-md-0"
			:class="{ 'btn-background-black': redoBtnBlackBg }"
			large
			outlined
			@click="redoSession"
		>
			Refaire la session
		</v-btn>
		<div v-if="canShowNextExerciseBtn" class="mr-0 mr-md-4 mb-3 mb-md-0">
			<div>
				<v-btn class="hvr-grow actionButton" dark large @click="toNextSession">Session suivante</v-btn>
			</div>
		</div>

		<v-btn class="hvr-grow actionButton" outlined large @click="goToDashboard">Mon espace perso</v-btn>
	</div>
</template>

<script>
export default {
	name: 'ButtonsFinalSessionResult',
	computed: {
		canShowNextExerciseBtn() {
			if (this.sessionScore.score < 80) return false;
			if (this.formationProgress.formation.formation_finished === true) return false;
			if (this.formationProgress.formation.infinity_exercice !== true) return false;
			if (this.nextSession == null) return false;
			return true;
		},
		redoBtnBlackBg() {
			return this.formationProgress.formation.infinity_exercice == false || this.sessionScore.score < 80;
		},
	},
	data() {
		return { nextSession: null };
	},
	props: {
		sessionScore: Object,
		formationProgress: Object,
	},
	async mounted() {
		if (this.sessionScore.score >= 80) this.$confetti.start({ particlesPerFrame: 0.04 });
		this.nextSession = this.findNextSession(this.sessionScore.session_id); // null || Session
	},
	methods: {
		redoSession() {
			if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('exercice-replayExercice');

			this.$router.push({
				name: 'SessionDetailPage',
				params: { formationId: this.sessionScore.formation_id, sessionId: this.sessionScore.session_id },
			});

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('session-end-redo');
			}
		},
		goToDashboard() {
			if (process.env.VUE_APP_MIXPANEL == 'production') this.$mixpanel.track('exercice-goToDashboard');
			this.$store.dispatch('appState/selectFormationId', { formationId: this.sessionScore.formation_id });
			this.$router.push({ name: 'Redaction Dashboard Index', params: { formationId: this.sessionScore.formation_id } });

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('session-end-goToDashboard');
			}
		},
		toNextSession() {
			this.$router.push({
				name: 'SessionDetailPage',
				params: { formationId: this.sessionScore.formation_id, sessionId: this.nextSession._id },
			});

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('session-end-next');
			}
		},
		findNextSession(currentSessionId) {
			let nextSession = null;
			if (this.formationProgress == null || this.sessionScore == null) return;

			this.formationProgress.config.parcours_list.forEach((parcoursItem) => {
				parcoursItem.parcours_id.categories.forEach((category) => {
					const currentSession = category.sessions.find((session) => session._id === currentSessionId);
					if (currentSession == null) return;
					const nextSessions = category.sessions.filter((session) => session.position > currentSession.position);
					nextSession = nextSessions.sort((a, b) => a.position - b.position)[0]; // order asc
				});
			});

			return nextSession; // null || Session
		},
	},
};
</script>
<style lang="scss" scoped>
.btn-background-black {
	background-color: black !important;
	color: white !important;
}
::v-deep {
	.v-btn__content {
		height: 100%;
	}
}
</style>

import { getStartOfNextWednesday, getStartOfLastWednesday } from '../utils/time';

export const weeklySessionStrike = (sessionScores) => {
	if (sessionScores == null || sessionScores.length == 0) return 0;

	let stop = false;
	let strike = 0;
	let sessionWeekStartAt = ''; // >= Wed 00:00
	let sessionWeekEndAt = ''; // < Wed 00: 00

	sessionWeekStartAt = getStartOfLastWednesday(new Date());
	sessionWeekEndAt = getStartOfNextWednesday(new Date());

	while (stop === false) {
		const sessionScoreInWeekPeriod = sessionScores.filter((item) => {
			const updatedAt = new Date(item.updated_at);
			return updatedAt >= sessionWeekStartAt && updatedAt < sessionWeekEndAt;
		});
		if (sessionScoreInWeekPeriod.length === 0) stop = true;
		else {
			strike += 1;
			const oneDayBefore = new Date(sessionWeekStartAt.getTime() - 24 * 60 * 60 * 1000);
			sessionWeekStartAt = getStartOfLastWednesday(oneDayBefore);
			sessionWeekEndAt = getStartOfNextWednesday(oneDayBefore);
		}
	}
	return strike;
};

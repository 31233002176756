<template>
	<v-card class="shadow-blue pa30">
		<h2>Assiduité</h2>
		<p class="ortho-text-gray">Ta productivité rédactionnelle s'améliore de jour en jour, sois régulier</p>
		<p class="wordingStrike ortho-text-green text-center font-weight-black">
			<template v-if="formation.infinity_exercice">
				<span class="bigger-text"> <span class="mr-1">🔥</span>{{ strike }} </span>
				<span>jour<span v-if="strike > 1" style="font-size: 20px">s</span> d'affilée</span>
			</template>
			<template v-else>
				<span class="bigger-text"> {{ strike }} </span>
				<span>semaine<span v-if="strike > 1" style="font-size: 20px">s</span> d'affilée</span>
				<span class="ml-1 bigger-text">👏</span>
			</template>
		</p>
	</v-card>
</template>

<script>
import { weeklySessionStrike } from '@/utils/weeklyStrike.js';
import { dailySessionStrike } from '@/utils/dailyStrike.js';
export default {
	name: 'SessionStrike',
	props: { formation: Object },
	data() {
		return { strike: 0 };
	},
	async mounted() {
		const sessionScores = await this.$store.dispatch('sessionScore/fetchSessionScores', {
			formationId: this.formation._id,
		});
		if (this.formation.infinity_exercice == false) this.strike = this.countWeeklyStrike(sessionScores);
		else this.strike = this.countDailyStrike(sessionScores);
	},
	methods: {
		countWeeklyStrike(sessionScores) {
			const strike = weeklySessionStrike(
				[...sessionScores].sort((a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				})
			);
			return strike === 0 ? 1 : strike;
		},
		countDailyStrike(sessionScores) {
			const strike = dailySessionStrike(
				[...sessionScores].sort((a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				})
			);
			return strike === 0 ? 1 : strike;
		},
	},
};
</script>

<style lang="scss" scoped>
.wordingStrike {
	.bigger-text {
		font-size: 30px;
	}

	span:nth-child(2) {
		font-size: 20px;
	}
}
</style>

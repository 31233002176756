<template>
	<v-card class="shadow-blue pa30">
		<h2>Performance à la loupe</h2>
		<p>
			<span class="ortho-text-gray">Tes points s'additionnent à ton score de progression</span>
		</p>
		<div class="block-performance-wrapper d-flex">
			<EachBlockPerformance
				v-for="block in getQuizBlocks()"
				:sessionBlock="block"
				:key="block._id"
				:sessionScore="sessionScore"
			/>
			<EachBlockPerformance
				v-for="block in getReviewBlocks()"
				:sessionBlock="block"
				:key="block._id"
				:sessionScore="sessionScore"
			/>
		</div>
	</v-card>
</template>

<script>
import EachBlockPerformance from './EachBlockPerformance.vue';
export default {
	name: 'SessionBlocksPerformance',
	components: { EachBlockPerformance },
	props: { session: Object, sessionScore: Object },
	methods: {
		getQuizBlocks() {
			if (this.session == null || this.session.sessionBlocks == null) return [];
			return this.session.sessionBlocks.filter((block) => block.type === 'quiz');
		},
		getReviewBlocks() {
			if (this.session == null || this.session.sessionBlocks == null) return [];
			return this.session.sessionBlocks.filter((block) => block.type === 'review');
		},
	},
};
</script>
<style lang="scss" scoped>
.block-performance-wrapper {
	flex-direction: column;
}
</style>

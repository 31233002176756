<template>
	<div class="text-center block-quiz-performance">
		<!-- Start Each Questionnaire -->
		<div
			v-for="(questionnaire, index) in sessionBlock.questionnaires"
			:key="questionnaire._id"
			class="block-quiz-performance-questionnaire py-8"
		>
			<p v-if="sessionBlock.type === 'quiz'" class="mb-0 ortho-text-gray"><b>Notion du jour</b></p>
			<p v-else-if="sessionBlock.type === 'review'" class="mb-0 ortho-text-gray"><b>Révision</b></p>

			<h5 class="questionnaire-title">{{ questionnaire.title }}</h5>
			<SessionBlockScoreChart
				class="mt-5 mb-12"
				:questionnaire="questionnaire"
				:trackReponses="sessionScore.trackResponses"
				:sessionBlockId="questionnaire.session_block_id"
			/>
			<div>
				<TrackItemAndScore
					v-for="track in sortTracksASC(questionnaire.tracks)"
					:key="track._id"
					:track="track"
					:sessionBlock="sessionBlock"
					:trackReponse="findTrackReponse(track._id, questionnaire.session_block_id)"
					class="mt-2"
				/>
			</div>
			<div v-if="index !== sessionBlock.questionnaires.length - 1"></div>
		</div>
		<!-- End Each Questionnaire -->
	</div>
</template>

<script>
import TrackItemAndScore from './TrackItemAndScore.vue';
import SessionBlockScoreChart from '@/components/charts/SessionBlockScoreChart.vue';
export default {
	name: 'EachBlockPerformance',
	props: { sessionBlock: Object, sessionScore: Object },
	components: { TrackItemAndScore, SessionBlockScoreChart },
	methods: {
		findTrackReponse(trackId, sessionBlockId) {
			return this.sessionScore.trackResponses.find(
				(trackReponse) => trackReponse.track_id === trackId && trackReponse.session_block_id === sessionBlockId
			);
		},
		sortTracksASC(track) {
			return [...track].sort((a, b) => a.position - b.position);
		},
	},
};
</script>
<style lang="scss" scoped>
.block-quiz-performance {
	display: flex;
	flex-direction: column;
	.questionnaire-title {
		font-size: 17px;
	}
}

.block-quiz-performance:not(:last-child),
.block-quiz-performance-questionnaire:not(:last-child) {
	border-bottom: 1px solid #d0d0d0;
}
</style>

<template>
	<div class="session-block-score-chart">
		<v-progress-circular
			class="progressCircle"
			:rotate="90"
			size="170"
			width="25"
			:value="calculateQuestionnaireScore"
			color="#34e5c8"
		>
			<span class="percent-score" size="20" color="#34e5c8">{{ calculateQuestionnaireScore }}%</span>
		</v-progress-circular>
	</div>
</template>

<script>
export default {
	name: 'SessionBlockScoreChart',
	props: { questionnaire: Object, trackReponses: Array, sessionBlockId: String },
	computed: {
		calculateQuestionnaireScore() {
			if (this.questionnaire == null || this.trackReponses == null) return '';
			let totalCoef = 0;
			let totalScore = 0;
			this.questionnaire.tracks.forEach((track) => {
				const trackResponse = this.trackReponses.find(
					(r) => r.track_id === track._id && r.session_block_id === this.sessionBlockId
				);
				if (trackResponse == null) return console.warn('Not found reponse');
				totalCoef = totalCoef + track.coefficient;
				totalScore = totalScore + trackResponse.score * track.coefficient;
			});
			if (totalCoef == 0) return 0;
			return Math.ceil(totalScore / totalCoef);
		},
	},
};
</script>

<style lang="scss" scoped>
.session-block-score-chart {
	.percent-score {
		font-family: 'Roboto';
		font-size: 25px;
		font-weight: bold;
	}
}
</style>

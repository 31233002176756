<template>
	<div class="pa-md-0 pa-3">
		<v-container class="feedback-title-container">
			<h4 class="ortho-text-gray">Nous aussi on veut s'améliorer ! La session t'a plu ?</h4>
		</v-container>
		<v-form v-if="!submitFeedback" class="row mx-auto col-md-6 col-12 flex-column">
			<star-rating
				class="mx-auto"
				v-model="rating"
				:star-size="40"
				@rating-selected="showComments = true"
				:show-rating="false"
			>
			</star-rating>

			<div v-if="showComments" class="mt20 row flex-column justify-center">
				<v-textarea
					v-model="comments"
					name="comments"
					color="grey"
					auto-grow
					rows="1"
					label="Un commentaire ? (facultatif)"
				></v-textarea>
				<v-btn
					:disabled="disabledSubmit"
					:dark="!disabledSubmit"
					color="#15c39a"
					class="hvr-grow mx-auto mt-2"
					@click="submit"
				>
					Envoyer
				</v-btn>
			</div>
		</v-form>
		<div v-else class="row mx-auto col-md-6 col-12 flex-column">
			<v-alert outlined type="success" color="#15c39a">Votre commentaire a bien été envoyé, merci</v-alert>
		</div>
	</div>
</template>

<script>
// import { mapGetters } from 'vuex';
import StarRating from 'vue-star-rating';

export default {
	name: 'FeedbackSession',
	components: { StarRating },
	data: () => {
		return {
			disabledSubmit: false,
			showComments: false,
			submitFeedback: false,
			rating: 0,
			comments: '',
		};
	},
	props: { user: Object },
	methods: {
		async submit() {
			this.disabledSubmit = true;
			const feedbackSession = {
				userId: this.user._id,
				sessionId: this.$route.params.sessionId,
				rating: this.rating,
				comments: this.comments,
			};
			await this.$store.dispatch('feedbackSession/sendFeedback', feedbackSession);
			this.submitFeedback = true;
		},
	},
};
</script>

<style scoped>
.feedback-title-container {
	text-align: center;
}
</style>

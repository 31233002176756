<template>
	<v-card class="shadow-blue pa30">
		<h2>{{ title }}</h2>
		<p>
			<span v-if="totalScoreInPercent >= randomNumberMasteryPercentage" class="ortho-text-gray">
				{{ subTitlePassed }}
			</span>
			<span v-else class="ortho-text-gray">{{ subTitleNotPassed }}</span>
		</p>
		<DoubleChartSessionScore :totalScoreInPercent="totalScoreInPercent" :displayPoint="displayPoint" />
	</v-card>
</template>

<script>
import moment from 'moment';
import DoubleChartSessionScore from '@/components/charts/DoubleChartSessionScore.vue';

export default {
	name: 'SessionPerformance',
	props: {
		totalScoreInPercent: Number,
		title: String,
		subTitlePassed: String,
		subTitleNotPassed: String,
		displayPoint: Boolean,
	},
	components: {
		DoubleChartSessionScore,
	},
	computed: {
		randomNumberMasteryPercentage() {
			const day = moment().day();
			if (day % 2 == 0) return 70;
			else return 60;
		},
	},
};
</script>

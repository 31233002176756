<template>
	<div class="track-item-and-score">
		<p class="mb-0">
			<span>{{ track.position }}- </span>{{ track.title }}
		</p>
		<div class="d-flex">
			<span class="pr-2 ortho-text-green-flash">
				<b>{{ trackReponse.score }}%</b>
			</span>

			<v-icon @click="navigateToTrackPage" class="replay-icon" size="20px" color="black">mdi-reload</v-icon>
		</div>
	</div>
</template>

<script>
export default {
	name: 'TrackItemAndScore',
	props: {
		track: Object,
		sessionBlock: Object,
		trackReponse: Object,
	},
	computed: {
		trackRouteParams() {
			const { sessionId, formationId } = this.$route.params;

			return {
				name: 'TrackPage',
				params: {
					sessionId,
					formationId,
					sessionBlockId: this.sessionBlock._id,
					trackId: this.track._id,
				},
				query: {
					fromPage: 'session-result',
				},
			};
		},
	},

	methods: {
		navigateToTrackPage() {
			this.$router.push(this.trackRouteParams);

			if (process.env.VUE_APP_MIXPANEL == 'production') {
				this.$mixpanel.track('session-end-replayTrack');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.track-item-and-score {
	min-width: 200px;
	display: flex;
	justify-content: space-between;
	gap: 8px;
	align-items: center;
	p {
		text-align: left;
		font-size: 16px;
	}
	.replay-icon {
		transform: rotate(-90deg) translateX(2px);
	}
}
</style>

import { getStartOfDay, getEndOfDay } from '@/utils/time.js';

export const dailySessionStrike = (sessionScores) => {
	if (sessionScores == null || sessionScores.length == 0) return 0;

	let stop = false;
	let strike = 0;
	let dayStartAt = ''; // >=  00:00
	let dayEndAt = ''; // < 00: 00

	dayStartAt = getStartOfDay(Date.now());
	dayEndAt = getEndOfDay(Date.now());

	while (stop === false) {
		const sessionScoreInDayPeriod = sessionScores.filter((item) => {
			const updatedAt = new Date(item.updated_at);
			return updatedAt >= dayStartAt && updatedAt < dayEndAt;
		});
		if (sessionScoreInDayPeriod.length === 0) stop = true;
		else {
			strike += 1;
			const oneDayBefore = new Date(dayStartAt.getTime() - 24 * 60 * 60 * 1000);
			dayStartAt = getStartOfDay(oneDayBefore);
			dayEndAt = getEndOfDay(oneDayBefore);
		}
	}
	return strike;
};
